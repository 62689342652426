<template>
  <div id="paiement-livraison">
    <div class="row">
      <div v-if="hub" class="col-12 col-md-6 col-lg-5">
        <h2>{{ $t('payment.delivery') }}</h2>
        <h3>{{ hub.name }}</h3>
        {{ hub.getStreet() }}<br>
        {{ hub.getZipCity() }}<br>
        {{ hub.getPhone() }}
      </div>
      <div v-if="cart && cart.delivery" class="col-12 col-md-6 col-lg-5">
        <h2 style="color:transparent">
          {{ $t('payment.delivery') }}
        </h2>
        <h3>{{ cart.delivery.date | date('dddd LL') }}</h3>
        <h3 v-if="cart.delivery.mode !== 'home'">{{ getHours(cart.delivery.date) }}</h3>
        <h3 v-else-if="slicedHours">{{ slicedHours.text }}</h3>
        <p>{{ cart.delivery.note }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaiementLivraison',
  props: {
    partnerId: { type: String }
  },

  data: () => ({
    loading: false,
    hub: null,
    hubTimetable: null,
    slicedHours: null
  }),

  computed: {
    shops() {
      return this.$store.getters['cart/shops']
    },
    cart() {
      return this.$store.getters['cart/cart']
    }
  },

  mounted() {
    this.loading = true
    return this.$store.dispatch('cart/get', { partnerId: this.partnerId })
      .then(cart => Promise.all([
        this.$store.dispatch('hub/get', { id: cart?.delivery?.salepoint }),
        this.$store.dispatch('hub/getOpenings', { id: cart?.delivery?.salepoint })
      ]))
      .then(data => {
        this.hub = data[0]
        this.hubTimetable = data[1]
        this.slicedHours = this.cart.getDeliverySlicedHours(data[1])
      })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },

  methods: {

    getHours(date) {
      if (!this.hub || !this.hubTimetable) {
        return
      }
      return this.hub.getOpeningHours(date, this.hubTimetable)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#paiement-livraison {
    text-align: left;
    background-color: var(--yellow-color);
    margin-top: 20px;
    padding: 30px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
        margin-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 10%;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 10px;
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
    }
    .modifier-addresse {
        padding-top: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid white;

        img {
            padding-left: 20px;
        }
    }

    .clock-btn {
      width: 40px;
      cursor: pointer;
    }
}

</style>
