<template>
  <div>
    <CartCheckoutHeader
      :section3="true"
      :title="$t('payment.title')"
    />
    <div class="mt-5 paiement-totals">
      <Totals :partner-id="partnerId" />
    </div>
    <PaiementAdresse />
    <PaiementLivraison :partner-id="partnerId" />
    <PaiementCommande :partner-id="partnerId" />
    <FooterSpacer />
  </div>
</template>

<script>
import CartCheckoutHeader from '@/components/CartCheckout/CartCheckoutHeader.vue'
import Totals from '@/components/CartCheckout/Totals.vue'
import PaiementAdresse from '@/components/CartCheckout/PaiementAdresse.vue'
import PaiementLivraison from '@/components/CartCheckout/PaiementLivraison.vue'
import PaiementCommande from '@/components/CartCheckout/PaiementCommande.vue'
import FooterSpacer from '@/components/Layout/FooterSpacer'

export default {
  name: 'ViewPaiement',
  components: {
    CartCheckoutHeader,
    Totals,
    PaiementAdresse,
    PaiementLivraison,
    PaiementCommande,
    FooterSpacer
  },
  props: {
    partnerId: { type: String }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

.paiement-totals {
    @include media-breakpoint-up(md) {
        margin-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 10%;
    }
}
#paiement-commande {
  .arrow {
    display: none;
  }
}
</style>
