<template>
  <div id="paiement-commande-item">
    <div class="row cart-item">
      <div class="commercant col-12 col-lg-3">
        {{ shop.name }}
      </div>
      <div class="sous-total-text d-none d-lg-flex justify-content-lg-end col-lg-3">
        {{ $t('payment.subTotal') }}
      </div>
      <div class="qty col-8 col-md-3 col-lg-2">
        {{ shop.getTotalItems() }} articles
      </div>
      <div class="sous-total-text col-md-4 d-none d-md-flex justify-content-md-end d-lg-none">
        {{ $t('payment.subTotal') }}
      </div>
      <div class="col-4 col-md-5 offset-lg-1 col-lg-3 text-price">
        {{ shop.getTotalPrice() | price }}
      </div>
    </div>
    <template v-if="shop.hasFees()">
      <div
        v-for="(fee, index) in shop.getFees()"
        :key="index"
        class="row cart-item"
        :class="{ 'promocode-fee': shop.getFeeTotal(fee) < 0 }"
      >
        <div class="sous-total-text col-8 col-md-7 d-flex justify-content-lg-end">
          <template v-if="fee.type !== 'promocode'">
            {{ $t(`cart.fees.${fee.type}`) }} <span v-if="fee.rate">({{ fee.rate }}%)</span>
          </template>
          <template v-else>
            {{ fee.label }}
          </template>
        </div>
        <div class="col-4 col-md-5 offset-lg-2 col-lg-3 bold text-price">
          {{ shop.getFeeTotal(fee) | price }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'PaiementCommandeItem',
  props: {
    shop: { type: Object }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#paiement-commande-item .cart-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    text-align: left;
    color: white;
    padding: 20px 0;

    .categorie-icon img {
      max-height: 30px;
      @include media-breakpoint-up(lg) {
        max-height: 40px;
      }
    }
    .sous-total-text, .qty, .chf {
      font-weight: 400;
    }

    .text-price {
      max-width: 150px;
      text-align: right;
    }
}
</style>
