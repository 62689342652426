<template>
  <div id="paiement-adresse">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5">
        <h2>{{ $t('payment.billingAddress') }}</h2>
        {{ address.civility }} {{ user.firstname }} {{ user.lastname }}<br>
        {{ address.street }}<br>
        {{ address.zip }} {{ address.city }}<br>
        {{ $t('payment.phoneShort') }} {{ address.phone }}
      </div>
      <div class="col-12 mt-5 mt-md-0 col-md-6 col-lg-5">
        <h2>{{ $t('payment.deliveryAddress') }}</h2>
        {{ deliveryAddress.civility }} {{ deliveryAddress.firstname }} {{ deliveryAddress.lastname }}<br>
        {{ deliveryAddress.street }}<br>
        {{ deliveryAddress.zip }} {{ deliveryAddress.city }}<br>
        {{ $t('payment.phoneShort') }} {{ deliveryAddress.phone }}
      </div>
    </div>
    <div class="row modifier-addresse" :class="{ active: updateAddress }">
      <div class="addresse-titre col-12" @click="updateAddress = !updateAddress">
        <span>{{ $t('payment.updateBillingAddress') }}</span>
        <img src="@/assets/modifier-addresse-arrow.svg">
      </div>
      <div v-if="updateAddress" class="col-12 col-lg-10 mt-5">
        <InfosPersoAdresse hide-newsletter />
      </div>
    </div>
  </div>
</template>

<script>
import InfosPersoAdresse from '@/components/EspacePerso/InfosPersoAdresse'

export default {
  name: 'PaiementAdresse',
  components: { InfosPersoAdresse },
  data: () => ({
    updateAddress: false
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    address() {
      return this.user.coordinate
    },

    deliveryAddress() {
      return this.user.deliveryCoordinate || this.user.coordinate
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#paiement-adresse {
    text-align: left;
    background-color: var(--yellow-color);
    padding: 30px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 10%;
    }
    h2 {
        font-size: 18px;
        font-weight: 700;
    }
    .modifier-addresse {
        padding-top: 30px;
        padding-bottom: 10px;
        //border-bottom: 1px solid white;

        span {
          width: 52%;

          @include media-breakpoint-up(md) {
            width: auto !important;
          }
        }

        img {
            padding-left: 20px;
        }

        .addresse-titre {
            font-weight: bold;
            display: flex;
            align-items: center;
        }

        &.active {
          img {
            transform: rotate(90deg);
            padding: 0;
          }
        }
    }
}

</style>
