<template>
  <form
    class="infoperso-coord"
    @submit.stop.prevent="submit"
  >
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="date-of-birth">{{ $t('user.birthdate') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <DateField v-model="$v.user.birthdate.$model" :rules="$v.user.birthdate" :max="maxBirthdate" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="date-of-birth">{{ $t('user.fullname') }}</label>
      </div>
      <div class="col-4 col-md-3 right-padding">
        <UserCourtesyField v-model="$v.user.courtesyTitle.$model" :rules="$v.user.courtesyTitle" />
      </div>
      <div class="col-8 col-md-3 left-padding">
        <UserNameField v-model="$v.user.firstname.$model" :rules="$v.user.firstname" name="firstname" :placeholder="$t('user.firstname')" />
      </div>
      <div class="offset-4 col-8 offset-md-0 col-md-3 left-padding">
        <UserNameField v-model="$v.user.lastname.$model" :rules="$v.user.lastname" name="lastname" :placeholder="$t('user.lastname')" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="address">{{ $t('user.street') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserStreetField v-model="$v.user.coordinate.street.$model" :rules="$v.user.coordinate.street" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="ville">{{ $t('user.cityGroup') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserZipField v-model="$v.user.coordinate.zip.$model" :rules="$v.user.coordinate.zip" :city.sync="$v.user.coordinate.city.$model" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="pais">Pays</label>
      </div>
      <div class="col-8 col-md-6">
        <UserCountryField v-model="$v.user.coordinate.pays_id.$model" :rules="$v.user.coordinate.pays_id" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="address">{{ $t('user.phone') }}</label>
      </div>
      <div class="col-8 col-md-6">
        <UserPhoneField v-model="$v.user.coordinate.phone.$model" :rules="$v.user.coordinate.phone" />
      </div>
    </div>
    <template v-if="!hideNewsletter">
      <div class="row mt-5 mb-4">
        <CheckboxField
          v-model="user.newsletter.classic"
          class="col-12 offset-md-3 col-md-8"
          :label="$t('user.newsletter')"
        />
      </div>
    </template>
    <div class="row submit-button">
      <div class="col-12 offset-md-3 col-md-2">
        <label />
        <Button type="submit" :text="$t('user.submit')" :disabled="disabled" />
      </div>
    </div>
  </form>
</template>

<script>
import DateField from '@/components/UI/DateField'
import UserCourtesyField from '@/components/User/UserCourtesyField'
import UserNameField from '@/components/User/UserNameField'
import UserCountryField from '@/components/User/UserCountryField'
import UserZipField from '@/components/User/UserZipField'
import UserStreetField from '@/components/User/UserStreetField'
import UserPhoneField from '@/components/User/UserPhoneField'
import CheckboxField from '@/components/UI/CheckboxField'
import Button from '@/components/UI/Button'
import { required } from 'vuelidate/lib/validators'
import lodash from 'lodash'
import moment from 'moment'

const maxBirthdate = moment().subtract(18, 'year').toDate()

export default {
  name: 'InfosPersoAdresse',
  components: {
    DateField,
    UserNameField,
    UserCourtesyField,
    UserCountryField,
    UserStreetField,
    UserZipField,
    UserPhoneField,
    Button,
    CheckboxField
  },

  props: {
    hideNewsletter: { type: Boolean }
  },

  data: () => ({
    maxBirthdate: maxBirthdate,
    loading: false,
    user: {
      birthdate: null,
      courtesyTitle: null,
      firstname: null,
      lastname: null,
      coordinate: {
        street: null,
        city: null,
        zip: null,
        pays_id: null,
        phone: null
      },
      newsletter: {
        classic: false,
        promo: false
      }
    }
  }),

  validations: {
    user: {
      birthdate: {
        required,
        birthdate(v) {
          if (!v) {
            return true
          }
          const date = moment(v)
          return date.isValid() && date.toDate() <= maxBirthdate
        }
      },
      courtesyTitle: { required },
      firstname: { required },
      lastname: { required },
      coordinate: {
        street: { required },
        city: { required },
        zip: { required },
        pays_id: { required },
        phone: { required }
      }
    }
  },

  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    }
  },

  mounted() {
    this.user = lodash.cloneDeep(lodash.pick(
      this.$store.getters['auth/user'],
      Object.keys(this.user)
    ))
  },

  methods: {
    submit() {
      if (this.disabled) {
        return
      }
      this.loading = true
      return this.$store.dispatch('auth/updateUser', { user: this.user })
        .then(res => (this.$success(res, { message: 'user.saved' })))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
