<template>
  <form
    v-if="cart"
    class="codepromoform"
    @submit.prevent.stop="submit"
  >
    <input
      v-model="code"
      type="text"
      class="codepromoinput"
      :placeholder="$t('payment.promoCode')"
      :disabled="loading || hasCode"
      @keydown="test"
    />
    <button
      type="submit"
      class="codepromosubmit"
      name="codepromosubmit"
      :disabled="loading || (!hasCode && !code)"
    >
      {{ $t(!hasCode ? 'payment.promoAdd' : 'payment.promoRemove') }}
    </button>
  </form>
</template>

<script>
const regexp = /[a-zA-Z0-9-_]/

export default {
  name: 'PaiementCommandePromo',

  props: {
    partnerId: { type: String }
  },

  data: () => ({
    loading: false,
    code: null
  }),

  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    },

    hasCode() {
      return this.cart?.hasManualPromocode()
    }
  },

  mounted() {
    this.autoApplyCode()
  },

  methods: {
    submit() {
      if (this.code) {
        return this.addCode().then(() => (this.code = null))
      }
      return this.removeCode()
    },

    addCode() {
      if (this.loading || this.hasCode) {
        return
      }
      this.loading = true
      return this.$store.dispatch('promo/applyPromocode', { code: this.code })
        .then(() => this.$store.dispatch('cart/get', { partnerId: this.partnerId, force: true }))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    removeCode() {
      this.loading = true
      return this.$store.dispatch('promo/unapplyPromocode')
        .then(() => this.$store.dispatch('cart/get', { partnerId: this.partnerId, force: true }))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    autoApplyCode() {
      this.loading = true
      return this.$store.dispatch('promo/autoPromocode')
        .then(() => this.$store.dispatch('cart/get', { partnerId: this.partnerId, force: true }))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    test(ev) {
      if (!ev.key.match(regexp)) {
        ev.stopPropagation()
        ev.preventDefault()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.codepromoform {
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
    }

    .codepromoinput {
        font-weight: 300;
        color: #3E3E3D;
        padding: 15px;
        min-width: 215px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;
        box-shadow: inset 1px 1px 2px #00000059;
        height: 60px;
        font-size: 18px;
        font-family: inherit;
        &:focus-visible {
            outline: transparent;
        }
    }
    .codepromosubmit {
        background-color: var(--red-color);
        font-size: 18px;
        height: 60px;
        border: none;
        box-shadow: 1px 1px 3px #0000008c;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        min-width: 90px;
        color: white;
        margin: 0px !important;
        cursor: pointer;
    }
}
</style>
