<template>
  <div id="paiement-commande">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5">
        <h2>{{ $t('payment.order') }}</h2>
      </div>
    </div>
    <PaiementCommandeItem
      v-for="shop in shops"
      :key="shop.id"
      :shop="shop"
    />
    <template v-if="cart">
      <div class="row sous-total-marchandises pt-4">
        <div class="col-8 col-md-7 d-flex justify-content-lg-end">
          {{ $t('payment.subTotalStuff') }}
        </div>
        <div class="col-4 col-md-5 offset-lg-2 col-lg-3 text-price">
          {{ cart.getTotalPriceStuff() | price }}
        </div>
      </div>
      <template v-if="cart.hasFees()">
        <div
          v-for="(fee, index) in cart.getFees()"
          :key="index"
          class="row pt-4"
          :class="{ 'promocode-fee': cart.getFeeTotal(fee) < 0 }"
        >
          <div class="col-8 col-md-7 d-flex justify-content-lg-end bold">
            <template v-if="fee.type !== 'promocode'">{{ $t(`cart.fees.${fee.type}`) }} <span ng-if="fee.rate">({{ fee.rate }}%)</span></template>
            <template v-else>{{ fee.label }}</template>
          </div>
          <div class="col-4 col-md-5 offset-lg-2 col-lg-3 bold text-price">
            {{ cart.getFeeTotal(fee) | price }}
          </div>
        </div>
      </template>

      <div
        v-if="feeHub"
        class="row pt-4"
        :class="{ 'promocode-fee': !!cart.delivery.feeLabel }"
      >
        <div class="col-8 col-md-7 d-flex justify-content-lg-end bold">
          {{ feeHub.label }}
        </div>
        <div class="col-4 col-md-5 offset-lg-2 col-lg-3 bold text-price">
          {{ feeHub.amount | price }}
        </div>
      </div>

      <template v-if="needReserveMargin">
        <div class="row pt-4 bold">
          <div class="col-8 col-md-7 d-flex justify-content-lg-end">
            {{ $t('payment.totalStuff') }}
          </div>
          <div class="col-4 col-md-5 offset-lg-2 col-lg-3 text-price">
            {{ cart.getTotalPriceWithFees(true) | price }}
          </div>
        </div>
        <div v-if="needReserveMargin" class="row pt-4">
          <div class="col-8 col-md-7 d-flex justify-content-lg-end">
            {{ $t('payment.reserve', { percent: margin * 100 }) }} *
          </div>
          <div class="col-4 col-md-5 offset-lg-2 col-lg-3 text-price">
            {{ marginAmount | price }}
          </div>
        </div>
        <div class="row pt-4 total-reserve">
          <div class="col-8 col-md-7 text">
            <span class="bold">{{ $t('payment.totalReserve') }} **</span>
          </div>
          <div class="col-4 col-md-5 offset-lg-2 col-lg-3 bold text-price">
            {{ getTotalPriceWithMargin | price }}
          </div>
        </div>
        <div class="row pt-4 total-reserve">
          <div class="col-lg-7 text">
            <p><small>* {{ $t('payment.reserveHelp') }}</small></p>
            <p><small>** {{ $t('payment.help') }}</small></p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row pt-4 bold">
          <div class="col-8 col-md-7 d-flex justify-content-lg-end">
            {{ $t('payment.total') }}
          </div>
          <div class="col-4 col-md-5 offset-lg-2 col-lg-3 text-price">
            {{ cart.getTotalPriceWithFees(true) | price }}
          </div>
        </div>
      </template>
      <div class="row pt-4">
        <div class="col-lg-7">
          <PaiementCommandePromo :partner-id="partnerId" />
        </div>
      </div>
      <div class="row pt-2 payer-button">
        <div class="col-12 offset-lg-9 col-lg-3 button-pay">
          <Button
            type="button"
            color="red"
            :text="$t('payment.submit')"
            :disabled="loading"
            @click.stop.prevent="pay"
          />
        </div>
      </div>
      <div v-if="$config.forceDatatransProd" class="row pt-4">
        <div class="col-8 col-md-7 d-flex justify-content-lg-end">
          &nbsp;
        </div>
        <div class="col-4 col-md-5 offset-lg-2 col-lg-3">
          <div class="alert alert-danger">ATTENTION, IL S'AGIT DE VRAIS PAIEMENTS. VOUS DEVEZ UTILISER VOTRE VRAIE CARTE DE CRÉDIT, ET VOUS SEREZ VRAIMENT DÉBITÉ!</div>
        </div>
      </div>
      <div v-if="paymentError" class="row pt-4">
        <div class="col-8 col-md-7 d-flex justify-content-lg-end">
          &nbsp;
        </div>
        <div class="col-4 col-md-5 offset-lg-2 col-lg-3">
          <div class="alert alert-danger">{{ paymentError.message }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PaiementCommandeItem from '@/components/CartCheckout/PaiementCommandeItem.vue'
import PaiementCommandePromo from '@/components/CartCheckout/PaiementCommandePromo'
import Button from '@/components/UI/Button.vue'

export default {
  name: 'PaiementCommande',
  components: {
    PaiementCommandeItem,
    PaiementCommandePromo,
    Button
  },

  props: {
    partnerId: { type: String }
  },

  data: () => ({
    payform: 'payform',
    transactionId: null,
    loading: false,
    feeHub: null,
    paymentError: null
  }),

  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    },
    shops() {
      return this.$store.getters['cart/shops']
    },
    margin() {
      return this.$config.cartMarginPercent
    },
    marginAmount() {
      return this.cart?.getTotalPriceWithFees(true) * this.margin
    },
    getTotalPriceWithMargin() {
      return this.cart?.getTotalPriceWithMargin(this.margin, true)
    },
    needReserveMargin() {
      return this.cart?.needReserveMargin()
    },
    isPaymentException() {
      return this.$route.query.datatransTrxId && ['exception', 'failed'].indexOf(this.$route.query.type) !== -1
    },
    isPaymentCancel() {
      return this.$route.query.datatransTrxId && ['cancel'].indexOf(this.$route.query.type) !== -1
    }
  },

  watch: {
    getTotalPriceWithMargin: 'init'
  },

  mounted() {
    // add datatrans script
    const datatrans = window.document.createElement('script')
    datatrans.setAttribute('src', this.$config.datatransScript)
    window.document.head.appendChild(datatrans)

    this.loading = true
    return Promise
      .all([
        this.$store.dispatch('cart/get', { partnerId: this.partnerId }),
        this.isPaymentException && this.$store.dispatch('cart/getPaymentError', { id: this.$route.query.datatransTrxId }).then(msg => {
          this.paymentError = msg
          return this.$err(msg)
        }),
        this.isPaymentCancel && Promise.resolve().then(() => {
          return this.$warn({ message: this.$i18n.t('payment.cancelled') })
        })
      ])
      .then(() => this.init())
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },

  methods: {

    init() {
      const cart = this.cart
      if (!cart) {
        return
      }

      if (cart.delivery.type === 'hub') {
        this.feeHub = {
          type: cart.delivery.mode === 'home' ? 'deliveryhome' : 'hub',
          amount: cart.delivery.fee,
          label: `${this.$i18n.t('cart.fees.promocodehubpartial', { total: cart.delivery.feeTotal })} (${cart.delivery.feeCode})`
        }
        if (!cart.delivery.feeLabel) {
          this.feeHub.label = this.$i18n.t(`cart.fees.${this.feeHub.type}`)
        } else if (!cart.delivery.fee) {
          this.feeHub.label = `${this.$i18n.t('cart.fees.promocodehub')} (${this.cart.delivery.feeCode})`
        }
      }
    },

    pay() {
      this.loading = true
      this.$store.commit('load/loading', { loading: true })
      return this.$store.dispatch('cart/checkCheckout')
        .then(() => this.$store.dispatch('cart/getCheckoutParams'))
        .catch(err => {
          this.$store.commit('load/loading', { loading: false })
          throw err
        })
        .then(data => {
          // eslint-disable-next-line
          Datatrans.startPayment({
            transactionId: data.transactionId,
            closed: () => {
              // if we bypass standard workflow and we close popup before it's loaded
              this.$store.commit('load/loading', { loading: false })
            },
            loaded: () => {
              // when loaded, stop loading
              this.$store.commit('load/loading', { loading: false })
            },
            error: err => {
              // if error occurs, stop loading
              this.$store.commit('load/loading', { loading: false })
              this.$err(err)
            }
          })
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#paiement-commande {
    text-align: left;
    background-color: var(--yellow-color);
    margin-top: 20px;
    padding: 30px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
        margin-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 10%;
    }
    h2 {
        font-size: 18px;
        font-weight: 700;
    }
    .bold {
        font-weight: 700;
    }
    .sous-total-marchandises {
        border-top: 1px solid white;

        .sous-total {
            padding-left: 15px;
            font-weight: 700;
        }
    }
    .total-reserve .text{
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
            text-align: right;
        }
    }

    .text-price, .button-pay {
      max-width: 150px;
      text-align: right;
    }
}

</style>
